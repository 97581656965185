import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SensorFiltersComponent } from '../../../sensor-filters/sensor-filters.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { UtilsService } from '../../../../services/utils.service';
import { FormControl } from '@angular/forms';
import { ChipType, MeasureControlsOptions, SelectedChip, UsageChip } from '../../../../shared/measures-component.model';
import {
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  skip,
  startWith,
  Subject,
  takeUntil
} from 'rxjs';
import { Property } from '../../../../shared/property.model';
import {
  BacMeasuresResolverConfig,
  BaseMeasuresResolverConfig,
  SensorMeasuresResolverConfig,
  StructureMeasuresResolverConfig
} from '../../../../shared/resolver-data.model';
import { AppInitializerService } from '../../../../services/app-initializer.service';
import { MeasuresService } from '../../../../services/measures.service';
import { DataTarget } from '../../../../shared/enums.model';
import { RegisterSensorFilter } from 'src/app/shared/sensor.model';
import { StsGroupType } from '@geomatys/ngx-core/sensor-things';
import { SensorBillboardService } from '../../../../services/sensor-billboard.service';
import { ViewerService } from '../../../../services/viewer.service';

@Component({
  selector: 'app-measures-controls',
  templateUrl: './measures-controls.component.html',
  styleUrls: ['./measures-controls.component.scss']
})
export class MeasuresControlsComponent extends SensorFiltersComponent implements OnInit, OnDestroy {

  @Input() startObsDate: string | undefined;
  @Input() endObsDate: string | undefined;

  isFilteringActive = true;
  usageOptions!: Observable<string[]>;

  protected usageCtrl = new FormControl();
  protected selectedUsages: Set<string> = new Set();
  private availableUsages: Array<string> = [];
  private _options!: MeasureControlsOptions;
  private destroy$ = new Subject<void>();

  constructor(
    route: ActivatedRoute,
    router: Router,
    apiService: ApiService,
    utilsService: UtilsService,
    private measureService: MeasuresService,
    sensorBillboardService: SensorBillboardService,
    viewerService: ViewerService,
  ) {
    super(route, router, apiService, utilsService, sensorBillboardService, viewerService);
    this.measuresForm.addControl('usage', this.usageCtrl, { emitEvent: false });

    combineLatest([
      this.measuresForm.get('startDate')!.valueChanges.pipe(startWith('')),
      this.measuresForm.get('endDate')!.valueChanges.pipe(startWith('')),
    ])
      .pipe(
        skip(1),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.goToFilteredMeasures();
      });
  }

  toggleFiltering(): void {
    this.isFilteringActive = !this.isFilteringActive;
    this.goToFilteredMeasures();
  }


  getTooltip(): string {
    return this.isFilteringActive ? 'MEASURES.DEACTIVATE' : 'MEASURES.ACTIVATE';
  }

  public ngOnInit(): void {
    this.usageOptions = this.usageCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.availableUsages, this.selectedUsages)),
    );

    this.measureService.measureUpdated.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.root = value.treeRoot;
      }
    });

    this.measureService.groupingChanged.pipe(takeUntil(this.destroy$)).subscribe({
      next: value => {
        this.groupType = value;
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  override goToFilteredMeasures(): void {
    let params: RegisterSensorFilter & { groupType?: StsGroupType } = { groupType: this.groupType };
    if (this.isFilteringActive) {
      params = this.parseQueryParams();
    }
    this.router.navigate(['./'], { relativeTo: this.route, queryParams: params }).then(() => {
      const config: BaseMeasuresResolverConfig = {
        params,
        graphsPerLine: AppInitializerService.CONFIG.measuresGraphsPerLine,
        pageSize: AppInitializerService.CONFIG.measuresPageSize,
        name: this._options.name
      };
      switch (this._options.target) {
        case DataTarget.SENSOR:
          const sensorConfig: SensorMeasuresResolverConfig = {
            target: DataTarget.SENSOR,
            sensorId: this._options.sensorId,
            ...config
          };
          this.measureService.updateMeasures(sensorConfig);
          break;
        case DataTarget.BAC:
          const bacConfig: BacMeasuresResolverConfig = {
            target: DataTarget.BAC,
            bacId: this._options.bacId,
            ...config
          };
          this.measureService.updateMeasures(bacConfig);
          break;
        case DataTarget.STRUCTURE:
          const structureConfig: StructureMeasuresResolverConfig = {
            target: DataTarget.STRUCTURE,
            structureId: this._options.structureId,
            structureAcronym: this._options.structureAcronym,
            ...config
          };
          this.measureService.updateMeasures(structureConfig);
          break;
      }
    });
  }

  override getPropertiesByName(query: string): Observable<Array<Property>> {
    return this.apiService.properties.searchByName(query)
      .pipe(
        map(res => res.filter(p => {
          if (this.selectedFamilies.size === 0) {
            return true;
          } else {
            return p.familly ? this.selectedFamilies.has(p.familly) : false;
          }
        }))
      );
  }

  override getAllProperties(): Observable<Array<Property>> {
    return this.apiService.properties.getAll()
      .pipe(
        map(res => res.filter(p => {
          if (this.selectedFamilies.size === 0) {
            return true;
          } else {
            return p.familly ? this.selectedFamilies.has(p.familly) : false;
          }
        }))
      );
  }

  override parseQueryParams(): Params {
    const params = super.parseQueryParams();
    if (this.selectedUsages.size > 0) {
      params['usage'] = Array.from(this.selectedUsages.keys());
    }
    if (this.selectedCategory.size > 0) {
      params['category'] = Array.from(this.selectedCategory.keys());
    }
    if (this.selectedState.size > 0) {
      params['state'] = Array.from(this.selectedState.keys());
    }
    return params;
  }

  override resetFilters(): void {
    this.usageCtrl.setValue(undefined);
    this.selectedUsages.clear();
    this.selectedCategory.clear();
    super.resetFilters();
  }

  override initComponent(options: MeasureControlsOptions): void {
    this._options = options;
    super.initComponent(options);
    this.availableSensors = options.availableSensors;
    this.availableUsages = options.availableUsages;
    this.selectedUsages = new Set();
    this.selectedState = new Set();
    if (options.selectedUsages) {
      for (const u of options.selectedUsages) {
        this.selectedUsages.add(u);
        const chip: UsageChip = {
          type: ChipType.USAGE,
          name: u,
          label: this.getLabelFromName(u)
        };
        this.selectedChips.push(chip);
      }
    }
  }

  public override removeChip(item: SelectedChip, index: number): void {
    if (item.type === ChipType.USAGE) {
      this.selectedUsages.delete(item.name);
    }
    super.removeChip(item, index);
  }

  public resetUsage(): void {
    this.usageCtrl.setValue(undefined);
  }

  public searchUsage(): void {
    this.selectedUsages.add(this.usageCtrl.value);
    this.selectedChips.push({
      type: ChipType.USAGE,
      name: this.usageCtrl.value,
      label: this.getLabelFromName(this.usageCtrl.value)
    });
    this.goToFilteredMeasures();
    this.resetUsage();
  }

}
