<form [formGroup]="measuresForm" (keydown.enter)="$event.preventDefault()" class="flex flex-col gap-4 p-5">
  <h1>{{ 'MEASURES.TITLE_FILTER' | translate }}</h1>
  <div class="flex flex-row items-center pb-4 gap-2.5">
    <mat-chip-list #chipList class="w-full min-h-10 bg-white rounded-s px-2 py-1">
      <mat-chip
        (removed)="removeChip(chip, i)"
        *ngFor="let chip of selectedChips; let i = index"
        [matTooltip]="chip.name"
        [ngClass]="{
          'property': chip.type === chipType.PROPERTY || chip.type === chipType.FAMILY,
          'sensor': chip.type === chipType.STRUCTURE || chip.type === chipType.BAC || chip.type === chipType.SENSOR || chip.type === chipType.PROJECT_SUPERVISOR,
          'usage': chip.type === chipType.USAGE
        }"
      >
        {{ chip.label }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-list>
    <button mat-stroked-button
            (click)="toggleFiltering()"
            [ngClass]="{'bg-[#00a2e2]': isFilteringActive}"
            [matTooltip]="getTooltip() | translate"
            matTooltipPosition="above">
      <mat-icon class="flex items-center justify-center">
        <img alt="toggle-filters" src="assets/icon_filtres.svg">
      </mat-icon>
    </button>
    <button mat-stroked-button
            (click)="resetFilters()"
            matTooltip='{{ "MEASURES.RESET" | translate }}'
            matTooltipPosition="above">
      <mat-icon class="flex items-center justify-center">delete</mat-icon>
    </button>
  </div>

  <div>
    <h2>{{ 'MEASURES.AVAILABLE_DATA' | translate }} :</h2>
    <div class="filter w-full flex flex-row justify-between items-center gap-2.5 pb-4">

      <div class="flex flex-col">
        <mat-label>{{ "DATE_FROM" | translate }}</mat-label>
        <app-date-picker-wrapper
          formControlName="startDate"
          placeHolder="{{ startObsDate }}">
        </app-date-picker-wrapper>
      </div>
      <div class="flex flex-col">
        <mat-label>{{ "DATE_TO" | translate }}</mat-label>
        <app-date-picker-wrapper
          formControlName="endDate"
          placeHolder="{{ endObsDate }}">
        </app-date-picker-wrapper>
      </div>
    </div>
  </div>

  <div *ngIf="(projectSupervisorOptions$ | async) && projectSupervisors.length">
    <h2>{{ 'MEASURES.PROJECT.SUPERVISOR' | translate }} :</h2>
    <div class="filter flex flex-row pb-4">
      <gco-search-input class="simple-search-input">
        <input (keyup.enter)="searchProjectSupervisor()"
               [matAutocomplete]="auto4"
               class="input"
               formControlName="projectSupervisor"
               gcoInputRef
               placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
        <mat-icon (click)="resetSelectedProjectSupervisor()" class="clear-button" svgIcon="log-au-x-mark"></mat-icon>
        <mat-icon (click)="searchProjectSupervisor()" class="search-button" svgIcon="log-au-research"></mat-icon>
      </gco-search-input>
      <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayProjectSupervisor">
        <mat-option *ngFor="let projectSupervisor of projectSupervisors" [value]="projectSupervisor">
          {{ projectSupervisor.name }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>

  <div>
    <h2>{{ 'MEASURES.PROPERTIES' | translate }} :</h2>
    <div class="filter flex flex-col">
      <div class="flex flex-row pb-4">
        <gco-search-input class="simple-search-input">
          <input (keyup.enter)="searchProperties()" [matAutocomplete]="auto"
                 class="input"
                 formControlName="property"
                 gcoInputRef
                 placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
          <mat-icon (click)="resetProperties()" class="clear-button" svgIcon="log-au-x-mark"></mat-icon>
          <mat-icon (click)="searchProperties()" class="search-button" svgIcon="log-au-research"></mat-icon>
        </gco-search-input>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions$ | async" [matTooltip]="option.name" [value]="option.name">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="flex flex-row items-center self-end" *ngIf="propertyFamilies.length>0">
        <p class="filter-by-text">{{ 'MEASURES.FILTER_BY' | translate }} :</p>
        <mat-select (selectionChange)="addFamily()" formControlName="family">
          <mat-option *ngFor="let family of propertyFamilies" [value]="family">
            {{ family }}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>

  <div>
    <h2>{{ 'MEASURES.ATTRIBUTE_CATEGORY' | translate }} :</h2>
    <section class="flex flex-col pb-4 gap-2.5">
      <mat-checkbox (change)="setCategory($event.checked, 'phyto-sanitaire')" formControlName="phyto">
        {{ 'MEASURES.CATEGORY.PHYTO' | translate }}
      </mat-checkbox>
      <mat-checkbox (change)="setCategory($event.checked, 'micro-polluant')" formControlName="microP">
        {{ 'MEASURES.CATEGORY.MICRO_POLLUANT' | translate }}
      </mat-checkbox>
    </section>
  </div>

  <div>
    <h2>{{ 'MEASURES.USAGE' | translate }} :</h2>
    <div class="filter flex flex-row pb-4">
      <gco-search-input class="simple-search-input">
        <input gcoInputRef
               class="input"
               formControlName="usage"
               placeholder="{{'SEARCH_PLACEHOLDER' | translate}}"
               [matAutocomplete]="auto3"
               (keyup.enter)="searchUsage()">
        <mat-icon svgIcon="log-au-x-mark" class="clear-button" (click)="resetUsage()"></mat-icon>
        <mat-icon svgIcon="log-au-research" class="search-button" (click)="searchUsage()"></mat-icon>
      </gco-search-input>
      <mat-autocomplete #auto3="matAutocomplete">
        <mat-option *ngFor="let option of usageOptions | async" [matTooltip]="option" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>

  <div>
    <h2>{{ 'MEASURES.SENSOR_SELECT' | translate }} :</h2>
    <div class="filter flex flex-col">
      <div class="flex flex-row pb-4">
        <gco-search-input class="simple-search-input">
          <input (keyup.enter)="searchSensors()"
                 [matAutocomplete]="auto2"
                 class="input"
                 formControlName="sensor"
                 gcoInputRef
                 placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
          <mat-icon (click)="resetSelectedSensor()" class="clear-button" svgIcon="log-au-x-mark"></mat-icon>
          <mat-icon (click)="searchSensors()" class="search-button" svgIcon="log-au-research"></mat-icon>
        </gco-search-input>
        <mat-autocomplete #auto2="matAutocomplete">
          <mat-option *ngFor="let option of sensorOptions$ | async" [matTooltip]="option.name" [value]="option.name">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="flex flex-row items-center self-end" *ngIf="root">
        <p class="filter-by-text">{{ 'MEASURES.FILTER_BY' | translate }} :</p>
        <div [matMenuTriggerFor]="sensorTreeMenu" class="menu-trigger-outer">
          <div class="menu-trigger-inner"></div>
        </div>
        <mat-menu #sensorTreeMenu class="tree-display-panel">
          <app-tree-display (click)="$event.stopPropagation()" (itemSelected)="onItemSelected($event)"
                            [root]="root"></app-tree-display>
        </mat-menu>
      </div>
    </div>
  </div>

  <div>
    <h2>{{ 'SENSORS.FILTERS.SENSOR_TYPE' | translate }} :</h2>
    <div class="filter flex items-center">
      <section class="flex flex-col pb-4 gap-2.5" formGroupName="sensorTypes">
        <mat-checkbox (change)="setSensorType($event.checked, 'underground')" formControlName="underground">
          {{ 'SENSORS.FILTERS.TYPE.UNDERGROUND' | translate }}
        </mat-checkbox>
        <mat-checkbox (change)="setSensorType($event.checked, 'meteo')" formControlName="meteo">
          {{ 'SENSORS.FILTERS.TYPE.WEATHER' | translate }}
        </mat-checkbox>
        <mat-checkbox (change)="setSensorType($event.checked, 'surface')" formControlName="surface">
          {{ 'SENSORS.FILTERS.TYPE.SURFACE' | translate }}
        </mat-checkbox>
        <mat-checkbox (change)="setSensorType($event.checked, 'hydro-STD')" formControlName="hydroSTD">
          {{ 'SENSORS.FILTERS.TYPE.HYDRO_STD' | translate }}
        </mat-checkbox>
        <mat-checkbox (change)="setSensorType($event.checked, 'hydro-DEB')" formControlName="hydroDEB">
          {{ 'SENSORS.FILTERS.TYPE.HYDRO_DEB' | translate }}
        </mat-checkbox>
      </section>
    </div>
  </div>

  <div>
    <h2>{{ 'MEASURES.ADVANCED_SENSOR_ATTRIBUTE' | translate }} :</h2>
    <section class="flex flex-col pb-4 gap-2.5">
      <mat-checkbox (change)="setState($event.checked, 'active')" formControlName="active">
        {{ 'MEASURES.STATE.ACTIVE' | translate }}
      </mat-checkbox>
      <mat-checkbox (change)="setState($event.checked, 'idle')" formControlName="idle">
        {{ 'MEASURES.STATE.IDLE' | translate }}
      </mat-checkbox>
    </section>
  </div>

  <div>
    <h2>{{ 'MEASURES.ATTRIBUTE_AUTHORIZATION' | translate }} :</h2>
    <section class="flex flex-col pb-4 gap-2.5">
      <mat-checkbox (change)="setAuthorization($event.checked, 'authorized')" formControlName="authorized">
        {{ 'MEASURES.AUTHORIZATION.AUTHORIZED' | translate }}
      </mat-checkbox>
      <mat-checkbox (change)="setAuthorization($event.checked, 'unauthorized')" formControlName="unauthorized">
        {{ 'MEASURES.AUTHORIZATION.UNAUTHORIZED' | translate }}
      </mat-checkbox>
    </section>
  </div>

  <div>
    <h2>{{ 'MEASURES.ATTRIBUTE_QUANTIFICATION' | translate }} :</h2>
    <section class="flex flex-col pb-4 gap-2.5">
      <mat-checkbox (change)="setQualification($event.checked, 'quantified')" formControlName="quantified">
        {{ 'MEASURES.QUANTIFICATION.QUANTIFIED' | translate }}
      </mat-checkbox>
      <mat-checkbox (change)="setQualification($event.checked, 'detected')" formControlName="detected">
        {{ 'MEASURES.QUANTIFICATION.DETECTED' | translate }}
      </mat-checkbox>
    </section>
  </div>
</form>

