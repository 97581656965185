import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { combineLatest, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { SensorSummary } from '../shared/sensor.model';
import { Property } from '../shared/property.model';
import { AlertSearchFilter, FilterFormValues, ParseFilterAccessor } from '../shared/@types/alert/alert-filters';
import { UtilsService } from './utils.service';
import { AlertQualification } from '../shared/alert.model';
import { MeasureFilter } from '../shared/@types/form/measure-filter';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private utilsService: UtilsService) {
  }

  parseUrlParamsToForm<T extends Record<string, unknown>>(
    filterItems: string[],
    baseValue: T[],
    accessor: ParseFilterAccessor
  ): Set<T> {
    return new Set(
      filterItems.reduce((acc, filterItem) => {
        const prop = baseValue.find((value) => value[accessor] === filterItem);
        if (prop) {
          acc.push(prop);
        }
        return acc;
      }, [] as T[]));
  }

  formatFormValues(formValues: Partial<FilterFormValues>): AlertSearchFilter | MeasureFilter {
    const formattedFormValue: AlertSearchFilter = {};

    if (formValues?.startDate) {
      UtilsService.applyDateFilter(formattedFormValue, 'start', formValues.startDate);
    }
    if (formValues?.endDate) {
      UtilsService.applyDateFilter(formattedFormValue, 'end', formValues.endDate);
    }

    if (formValues?.qualifications) {
      formattedFormValue.qualification = Object.keys(formValues.qualifications).reduce((acc, curr) => {
        const currentKey = curr as AlertQualification;
        if (formValues.qualifications![currentKey]) {
          acc.push(currentKey);
        }
        return acc;
      }, [] as AlertQualification[]);
    }

    if (formValues?.sensors && !this.utilsService.onSensorRoute()) {
      formattedFormValue.sensorIds = [...formValues.sensors].map((sensor: SensorSummary) => sensor.sensorId);
    }

    if (formValues?.properties) {
      formattedFormValue.paramCodes = [...formValues.properties].map((property: Property) => property.propertyId);
    }

    return formattedFormValue;
  }

  initMultiSelectOptions<T>(
    form: FormGroup,
    innerInput: AbstractControl,
    filterFormControlName: string,
    defaultValue: Set<T>, valuesList: T[],
    destroy: Subject<void>
  ): Observable<T[]> {
    return combineLatest([
      innerInput.valueChanges.pipe(startWith('')),
      form.get(filterFormControlName)!.valueChanges.pipe(startWith(defaultValue))
    ]).pipe(
      takeUntil(destroy),
      map(([inputValue, currentValue]) => this.filterMultiSelectOptions(valuesList ?? [], inputValue, currentValue ?? new Set<T>()))
    );
  }

  private filterMultiSelectOptions<T extends SensorSummary | Property>(
    options: T[],
    value: string | T | null,
    formControlValue: Set<T>
  ): T[] {
    if (typeof value !== 'string') {
      return options.filter(option => !formControlValue.has(option));
    }
    return options.filter(option => !formControlValue.has(option) && option.name.toLowerCase().includes(value.toLowerCase()));
  }
}
