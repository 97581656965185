import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { SensorFiltersComponent } from '../../../sensor-filters/sensor-filters.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { UtilsService } from '../../../../services/utils.service';
import { SensorFiltersService } from '../../../../services/sensor-filters.service';
import { SensorFilterOptions } from '../../../../shared/sensor.model';
import { SensorBillboardService } from '../../../../services/sensor-billboard.service';
import { ViewerService } from '../../../../services/viewer.service';
import { combineLatest, distinctUntilChanged, skip, startWith, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sensor-filters-menu',
  templateUrl: './sensor-filters-menu.component.html',
  styleUrls: ['./sensor-filters-menu.component.scss']
})
export class SensorFiltersMenuComponent extends SensorFiltersComponent implements OnDestroy {

  // Possible refactor here. These two properties are used in the measure-data.component. The problem is that, for the filtering purposes,
  // in the measure component we should use the data streams and here the register (now we use register for both). If we stay with register for both
  // we could move the globalDates into the BaseSensorFilterOptions.
  @Input() globalStartDate!: string;
  @Input() globalEndDate!: string;

  @Output() closeMenu = new EventEmitter<boolean>();
  private _options!: SensorFilterOptions;
  private destroy$ = new Subject<void>();

  constructor(
    route: ActivatedRoute,
    router: Router,
    apiService: ApiService,
    utilsService: UtilsService,
    private sensorFilterService: SensorFiltersService,
    sensorBillboardService: SensorBillboardService,
    viewerService: ViewerService
  ) {
    super(route, router, apiService, utilsService, sensorBillboardService, viewerService);
    combineLatest([
      this.measuresForm.get('startDate')!.valueChanges.pipe(startWith('')),
      this.measuresForm.get('endDate')!.valueChanges.pipe(startWith('')),
    ])
      .pipe(
        skip(1),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.goToFilteredMeasures();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  override initComponent(options: SensorFilterOptions): void {
    this._options = options;
    super.initComponent(options);
  }

  override goToFilteredMeasures(): void {
    const queryParams = this.parseQueryParams();
    this.sensorFilterService.goToFilteredMeasures(this._options, queryParams);
    this.router.navigate(['./'], { relativeTo: this.route, queryParams }).then();
  }

  public close() {
    this.closeMenu.emit(true);
  }
}
